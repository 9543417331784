import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Tooltip, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas'
import dayjs from 'dayjs';
import MedienCarousel from "../Components/MedienCarousel";
import { API, API_BASE } from "../constant";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";
import { formConfig } from "../configs/ProfilAnerkennung.js";
const { Step } = Steps;
const { Option } = Select;


 function makeVisible(element) {
            if (element.style && element.style.display === 'none') {
                element.style.display = 'block';
                // add attribute to element for flag
                element.setAttribute('data-print', 'true');
            }

            Array.from(element.children).forEach(makeVisible);
        }
 function makeInVisible(element) {
 // check for data-print attribute
               // check if element has printHide class

               if (element.getAttribute('data-print') === 'true') {
                 element.style.display = 'none';
                 // remove attribute from element
                 element.removeAttribute('data-print');
                }
                Array.from(element.children).forEach(makeInVisible);
        }
function printContent(idToPrint) {


          var content = document.getElementById(idToPrint).innerHTML;
                      var printWindow = window.open('', '', 'height=800, width=1024');

                      // Ensure the printWindow is successfully created
                      if (printWindow) {
                          // Wait for the new window to load
                          printWindow.document.open();
                          printWindow.document.write('<html><head><title>Anerkennungsantrag Abzug</title>');

                          // Copy the full head of the original document
                          var head = document.querySelector('head').innerHTML;
                                      printWindow.document.write(head);
                                      printWindow.document.write('<style type="text/css">#heightBlock { overflow: hidden !important } .ant-select-selection-item-remove{ display: none !important } .ant-select-selection-item-content { white-space: initial !important } .ant-select-selection-item { height: 100% !important } * { -webkit-print-color-adjust: exact !important; } .ant-card-actions{ opacity: 0 !important; } .printHide{display: none !important; } .ant-card-head {display: none !important; } </style>')
                                      printWindow.document.write('</head><body style="pointer-events: none; padding-left: 10%; padding-right: 10%;"><br><br><h2>Abzug des Anerkennungsantrags</h2>');
                                      printWindow.document.write(content);
                                      printWindow.document.write('<div style="z-index:999;margin-top: -112px;position: absolute;width: 100%;"><br/><br/><br/><p><h3>Datum, Ort, Unterschrift:</h3></p><br><br/><br/><br/><br/><br/><br/><hr/><br><br></div>');
                                      printWindow.document.write('</body></html>');


                          // Wait for the content to be fully loaded before printing

setTimeout(() => {printWindow.print();
                              setTimeout(() => {
//                              printWindow.document.close();
                              },1000);
                              },1000);
                      } else {
                          console.error("Failed to open the print window.");
                      }
        }

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const fillForm = async (formInput) => {
    const fb = document.getElementById("antragForm");
    const hb = document.getElementById("heightBlock");
        makeVisible(document.getElementById("antragForm"));
    //fb.style.pointerEvents = 'none';
    hb.style.height = 'auto';
    setTimeout(() => {

        printContent("antragForm");

        hb.style.height = '414px';
    //fb.style.pointerEvents = '';
makeInVisible(document.getElementById("antragForm"));
    }, 100);
}

let sigPad={}
  const renderFormFields = (setErrorFields, isDisabled, fromInnerRender, formExterior, defaultValues, fileList,fileListAnlagen,handlePreview, handleChange, handleChangeAnlagen, handleCancel, formsUmbrella, formIndex, setAnbieterNummerIsFilled, setSigned, setSignedDocHochgeladen, formIndexOutter) => {
    return (formsUmbrella || []).map((forms, innerIndex) => {
        console.debug("!!forms", forms.forms);
        return (forms.forms || []).map((form, index) => {
          switch (form.type) {

               case 'header':
               return (<>
                    <Card
                      key={index}
                      bordered={false}
                      style={{
                        display: (formIndex === 6) ? 'block' : 'none',
                        marginBottom: '16px',
                        textAlign: 'left',
                        background: '#f0f2f5',
                      }}
                    >
                      <p style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                        {form.label}
                      </p>
                    </Card>

                    { renderFormFields(setErrorFields, isDisabled, true , formExterior, defaultValues, fileList,fileListAnlagen,handlePreview, handleChange,  handleChangeAnlagen, handleCancel, [{forms : form.content }], 999, setAnbieterNummerIsFilled, setSignedDocHochgeladen, formIndex) }

                    </>
                  );
            case 'string':
              return (<>
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6)) ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  onChange={(e) => {
                  if (form.label === 'Anbieternummer') {
                    setAnbieterNummerIsFilled(e.target.value != '')
                    console.log('Anbieternummer:', e.target.value)
                  }

                  }
                  }
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input />
                </Form.Item></>
              );
            case 'number':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input type="number" />
                </Form.Item>
              );
            case 'date':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <DatePicker
                        format={{
                          format: 'DD-MM-YYYY',
                          type: 'mask',
                        }}
                      />
                </Form.Item>
              );
            case 'select':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte auswählen ${form.label}!` }]}
                >
                  <Select placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'multiple select':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte auswählen ${form.label}!` }]}
                >
                  <Select mode="multiple" placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'checkbox':
              return (
                <Form.Item
                rules={ form.required === true ? [
                           {
                               required: true,
                                                 transform: value => (value || undefined),  // Those two lines
                                                 type: 'boolean',                           // Do the magic
                               message: 'Ihr Einverständnis ist erforderlich',
                           },
                       ] : undefined }
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                   key={index} name={form.key} valuePropName="checked">
                  <Checkbox>{form.label}  </Checkbox>
                </Form.Item>
              );
            case 'multiupload':
            return (
                <div className="printHide"  style={{ display: innerIndex === formIndex ? 'block' : 'none'}} >
                { isDisabled ? <MedienCarousel overwriteTitle={"Anlagen"} pickedData={(fileListAnlagen || [])} /> : <Form.Item
                  disabled={false}
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index} name={form.key} label={form.label}>

                  <Upload name="files" disabled={false}
                          crossOrigin={"use-credentials"}
                          data={{
                            ref: "api::anbieter-profil",
                            field: "anlagen",
                          }}
                            fileList={(fileListAnlagen || []).map((file) => {
                                  console.debug("!!file", file);
                                  if (!file.thumbUrl) {
                                      if ((((file || {}).response || {})[0] || {}).url) {
                                       file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                                      } else {
                                        file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                                      }
                                  }
                            return file;
                      })}
                      onPreview={handlePreview}
                      onChange={handleChangeAnlagen}

                          action={`${API}/upload`}
                          headers={{ "Authorization": `Bearer ${getToken()}`}}>
                    <Button icon={<UploadOutlined />}>Hochladen</Button>
                  </Upload>
                </Form.Item>}
                </div>
              );
            case 'upload':
              return (
              <div className="printHide" style={{ display: innerIndex === formIndex ? 'block' : 'none'}} >
                { isDisabled ? <MedienCarousel overwriteTitle={"Unterschriebener Antrag"} pickedData={(fileList || [])} /> : <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                  key={index} name={form.key} label={form.label}>

                  <Upload name="files"
                          crossOrigin={"use-credentials"}
                          data={{
                            ref: "api::anbieter-profil.anbieter-profil",
                            field: "signedpdf",
                          }}
                            fileList={(fileList || []).map((file) => {
                                  console.debug("!!file", file);
                                  if (!file.thumbUrl) {
                                      if ((((file || {}).response || {})[0] || {}).url) {
                                       file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                                      } else {
                                        file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                                      }
                                  }

                            return file;
                      })}
                      onPreview={handlePreview}
                      onChange={handleChange}

                          action={`${API}/upload`}
                          headers={{ "Authorization": `Bearer ${getToken()}`}}>
                    <Button icon={<UploadOutlined />}>Hochladen</Button>
                  </Upload>
                </Form.Item> }
                <hr/>
                <p style={{ display: isDisabled ? 'none': 'block' }}>Oder direkt online unterschreiben:</p>
                <div style={{ pointerEvents: isDisabled ? 'none' : 'all', margin: '0 auto', width: 600, border: '2px solid black' }}>
                <SignatureCanvas onEnd={() => setSigned(true)} ref={(ref) => { sigPad = ref }}  penColor='blue' canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} />
                <Button style={{ display: isDisabled ? 'none': 'block' }} onClick={() => {
                                     sigPad.clear()
                                   }}>
                  Unterschrift zurücksetzen
                </Button>
                </div>
                </div>
              );
              case 'button':
                return (<div>
                    <Button className={ "printHide" }
                    style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none'}}
                   onClick={() => formExterior.validateFields().then(() => { fillForm(formExterior, defaultValues) }).catch(errorInfo => {

                   setErrorFields(errorInfo.errorFields);
                   message.open({ type: 'error', content: 'Antrag noch nicht komplett. Bitte füllen Sie alle Pflichtfelder aus.', className: 'custom-class', style: { padding: '3rem', marginTop: '20vh', fontSize: '20px', backgroundColor: '#eb008c', fontWeight: 800 }, })
                 })} icon={<UploadOutlined />}>Antrag als PDF zum drucken und unterschreiben herunterladen</Button>
                </div>)
            case 'htmlbox':
              return (
                <Card className={ form.printHide ? "printHide" : "" }
                   key={index} bordered={false} style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 6))  ? 'block' : 'none', marginBottom: '16px', textAlign: 'left', background: '#f0f2f5' }}>
                      <div dangerouslySetInnerHTML={{ __html: form.htmlbox }} style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                      </div>
                    </Card>
              );
            default:
              return null;
          }
        })});
  };

function getCanvasString() {
    const canvas = document.querySelector('.sigCanvas');
    return canvas.toDataURL('image/png');
}

const FormComponent = ( {history, type, id, herstellerData} ) => {

  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();
  const [refId, setRefId] = useState('');
  const [current, setCurrent] = useState(0);
  const [anbieterNummerIsFilled, setAnbieterNummerIsFilled] = useState(false);
  const [signedDocHochgeladen, setSignedDocHochgeladen] = useState(false);
  const [errorFields, setErrorFields] = useState([]);


    const [form] = Form.useForm();
    const [defaultValues, setDefaultValues] = useState({});

    const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};


    const [fileListAnlagen, setFileListAnlagen] = useState(((veranstaltung || {}).anlagen || {}).data || []);
    const [fileList, setFileList] = useState(((veranstaltung || {}).signedpdf || {}).data || []);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [signed, setSigned] = useState(true);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {

    console.debug("file",file);
      if (!file.thumbUrl && !file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.thumbUrl || file.url || file.preview || ((((file || {}).attributes || {}).formats || {}).thumbnail || {}).url);
      setPreviewOpen(true);
      setPreviewTitle((((file || {}).attributes) || {}).name || file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
const handleChangeAnlagen = (props) => {
    console.debug("setting newFileListAnlagen", props);
    setFileListAnlagen(props.fileList);
}

useEffect(() => {
    const currentValues = form.getFieldsValue();
    // Check if form values are empty
    const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

    if (isFormEmpty) {
        form.setFieldsValue(defaultValues)
        if (defaultValues.unterschriebenesBildString) {
                    const base64String = defaultValues.unterschriebenesBildString;

                    const canvas = document.querySelector('.sigCanvas');
                    const ctx = canvas.getContext('2d');
                    const img = new Image();

                    img.onload = function() {
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    };

                    img.src = base64String;

        }

        if (defaultValues.signedpdf) {
            setFileList(defaultValues.signedpdf);
        }
        if (defaultValues.anlagen) {
            setFileListAnlagen(defaultValues.anlagen);
        }
    }
}, [form, defaultValues])

// on mount check if this user already has a veranstaltung

    useEffect(() => {
        const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};

        // check if we are on admin/aktionen/Profil
        if ((user || {}).Rolle === 'Anbieter' && history.location.pathname === `/admin/aktionen/Profil`) {
            const userveranstaltung = herstellerData.find((veranstaltung) => veranstaltung.createdby == user.id) || {};
            if ((userveranstaltung || {}).id) {
                history.push(`/admin/aktionen/Profil/${userveranstaltung.id}`);
            }
        }

        const defaultValues = {};

        if (Object.keys(veranstaltung.profilinformation || {}).length > 0) {
            Object.keys(veranstaltung.profilinformation).forEach((key) => {
                if (key === "AnerkennungDate" || key === "Datum Grundlagenseminar" ||
                 key === "Datum Grundlagensemina2r" || key === "Datum Leitungs- Dozentenseminar21" ||
                key === "Datum Leitungs- Dozentenseminar" || key === "Datum Grundlagensemi2nar" ||
                key === "Datum Leitungs- Dozentenseminar2" ||
                key === "Datum Leitun2gs- Dozentenseminar" || key === 'Datum Grundlagenseminar2') {
                    defaultValues[key] = dayjs(veranstaltung.profilinformation[key]);
                } else {
                    defaultValues[key] = veranstaltung.profilinformation[key];
                }

            });
        }

        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [herstellerData]);


  const saveFortschritt = async (status) => {
    setLoading(true);
    try {
        const data = form.getFieldsValue();
        const referent = herstellerData.find((referent) => referent.id == id);
        let response;
        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

                             dataToPost.unterschriebenesBildString = getCanvasString();


         if (!dataToPost["Bild"] || (referent && (dataToPost["Bild"] || {}).data)) delete dataToPost["Bild"];

                 dataToPost.signedpdf = (fileList || []).map( media => {
                             if (((media.response) || []) [0]) {
                                 return media.response[0];
                             } else {
                                 return media;
                             }
                          });

                                           dataToPost.anlagen = (fileListAnlagen || []).map( media => {
                                                       if (((media.response) || []) [0]) {
                                                           return media.response[0];
                                                       } else {
                                                           return media;
                                                       }
                                                    });

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Bild"]  = dataToPost.dragger[0].response[0];

//         if (dataToPost["Anerkennung soll gelten ab:"]) dataToPost["Anerkennung soll gelten ab:"] = dayjs(dataToPost["Anerkennung soll gelten ab:"]);

         dataToPost.createdby = user.id;

         if (status) {
            dataToPost.status = status;
         }

         delete dataToPost.dragger;
        //         delete dataToPost.Vergabestelle;
        //          if (Object.keys(dataToPost.Vergabestelle).length === 0 || typeof ((dataToPost || {}).Vergabestelle || {}).Punkte == "undefined") delete dataToPost.Vergabestelle;

        dataToPost.profilinformation = data;
let responseData = {};
      if (referent) {
        response = await fetch(`${API}/anbieter-profils/${referent.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
        responseData = await response.json();
        message.success("Daten erfolgreich gespeichert!");
        if (status) { history.push('/admin/aktionen'); }
      } else {
        response = await fetch(`${API}/anbieter-profils`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
        responseData = await response.json();
        message.success("Daten erfolgreich gespeichert!");

          if (status) {
            history.push('/admin/aktionen');
          } else {
            history.push(`/admin/aktionen/Profil/${responseData.data.id}`);
          }

      }
      // Update logic based on your application's requirements
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  const onNext = () => {
   // form.validateFields().then(() => {
      setCurrent(current + 1);
   // }).catch(errorInfo => {
      //message.error('Bitte füllen Sie alle Pflichtfelder aus.');
   // });
  };

  const onPrev = () => {
    setCurrent(current - 1);
  };

   const onChange = (value: number) => {
      console.log('onChange:', value);
      setCurrent(value);
    };

  const onFinish = (values) => {
      form.validateFields().then(() => {
            console.log('Form values:', values);
            message.success('Formular erfolgreich übermittelt!');
      }).catch(errorInfo => {
      setErrorFields(errorInfo.errorFields);
        message.error('Bitte füllen Sie alle Pflichtfelder aus.');
      });
  };

  const isDisabled = (user || {}).Rolle === 'ICW-Admin' || (user || {}).Rolle === 'Auditor' ;

  return (
     <Row gutter={[18, 18]}>
     <Col md={3}>

<Steps direction="vertical" onChange={onChange} current={current} style={{ marginBottom: '20px' }}>
  {formConfig.map((item, index) => {
  const isFaulty = errorFields.find((errorField) => (item.forms || []).map(iif => iif.key).includes((errorField.name || {})[0]));

  return (


    <Step
      key={index}
      title={
        <Tooltip arrow={false} placement="right" title={item.title || ""}>
          <span>{(item.title || "").substr(0, (item.title || "").indexOf(" "))}</span>
        </Tooltip>
      }
      icon={<Tooltip arrow={false} placement="right" title={item.title || ""}>
                     <span style={{ color: isFaulty ? 'red' : ''}}>{item.icon}</span>
                    </Tooltip>}
    />
  )})}
</Steps>
        </Col>
        <Col md={21}>
            <Form
            id="antragForm"
            disabled={isDisabled}
            initialValues={defaultValues} form={form} layout="vertical" onFinish={onFinish}>
              <Card
                    title={formConfig[current].title}
                  actions={[
                      (user || {}).Rolle !== 'ICW-Admin' && (user || {}).Rolle !== 'Auditor' && <Button onClick={() => saveFortschritt()} style={{ margin: '0 8px' }}
                          type="primary"
                          icon={<SaveOutlined />}>
                        Fortschritt speichern
                      </Button>,
                      <Button disabled={!(current > 0)} style={{ margin: '0 8px' }} onClick={onPrev}>
                        Zurück
                      </Button>,
                      <Button disabled={!(current < formConfig.length - 1)} type="primary" onClick={onNext}>
                        Weiter
                      </Button>,
                                           history.location.pathname.indexOf("/Seminar/") === -1 &&  (user || {}).Rolle === 'ICW-Admin' && <Button icon={<CheckCircleOutlined />} danger disabled={false} type="primary" onClick={() => {
                                                  history.push('/admin/aktionen/Anbieterverwaltung', {open: id});
                                            }}>
                                              Ablehnen
                                            </Button>,
                                            history.location.pathname.indexOf("/Seminar/") === -1 &&  (user || {}).Rolle === 'ICW-Admin' && <Button style={{ color: 'white', background: '#52c41a' }} icon={<CheckCircleOutlined />} disabled={false} type="primary" onClick={() => {
                                                  history.push('/admin/aktionen/Anbieterverwaltung', {openApprove: id});
                                            }}>
                                              Akzeptieren
                                            </Button>,



                      (user || {}).Rolle !== 'ICW-Admin' && (user || {}).Rolle !== 'Auditor' && <Button onClick={() => {
                                form.validateFields().then(() => {
                                      saveFortschritt("Eingereicht - in Bearbeitung")
                                }).catch(errorInfo => {

                                setErrorFields(errorInfo.errorFields);

                                message.open({
                                      type: 'error',
                                      content: 'Antrag noch nicht komplett. Bitte füllen Sie alle Pflichtfelder aus.',
                                      className: 'custom-class',
                                      style: {
                                        padding: '3rem',
                                        marginTop: '20vh',
                                        fontSize: '20px',
                                        backgroundColor: '#eb008c',
                                        fontWeight: 800
                                      },
                                    });
                                });



                      }} disabled={
                      /* is Anbieternummer filled? */
                        !signed && !(form.getFieldValue('Anbieternummer')) && !anbieterNummerIsFilled && !defaultValues.anbieternummer &&
                      !((fileList || []).length > 0)} type="primary" htmlType="submit">
                        Einreichen
                      </Button>
                    ]}
                    style={{ margin: '0 auto' }}>
                    <div id="heightBlock" style={{ height: 414, overflow: 'auto' }}>
                    { formConfig[current].htmlbox && <Card bordered={false} style={{ paddingBottom: '11px', marginBottom: '16px', textAlign: 'left', background: '#f0f2f5' }}>
                          <Typography.Title level={4} style={{ color: '#1890ff' }}>{form.label}</Typography.Title>
                          <div dangerouslySetInnerHTML={{ __html: formConfig[current].htmlbox }} ></div>
                        </Card> }

                      { renderFormFields(setErrorFields, isDisabled, false, form, defaultValues, fileList,fileListAnlagen, handlePreview, handleChange,handleChangeAnlagen, handleCancel, formConfig, current, setAnbieterNummerIsFilled, setSigned, setSignedDocHochgeladen) }
                      <Divider />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      </div>
                    </div>
      </Card>
    </Form>
            </Col>
        </Row>
  );
};

export default FormComponent;
