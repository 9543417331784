import React, { useEffect, useState, useContext, useRef } from "react";

import { Form, Input, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Tooltip, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import { useLocation } from 'react-router-dom';
import MedienCarousel from "../Components/MedienCarousel";
import { API, API_BASE } from "../constant";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";
import { Aktenaudit } from "../configs/Aktenaudit.js";
import { Pruefungsaudit } from "../configs/Pruefungsaudit.js";
import { Rezertifizierungsausdit } from "../configs/Rezertifizierungsausdit.js";
import { Seminaraudit } from "../configs/Seminaraudit.js";
const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const fillForm = async (formInput, defaultValues) => {
		// Step 1: Load the PDF form.
        const formUrl = '/pdfs/Anerkennung.pdf';
		const formPdfBytes = await fetch(formUrl).then((res) => {
			return res.arrayBuffer()
		});
		const pdfDoc = await PDFDocument.load(formPdfBytes);

		// Step 2: Retrieve the form fields.
		const form = pdfDoc.getForm();


		const formValues = formInput.getFieldsValue();

        try {
            form.getTextField('Anbieternummer').setText(formValues['anbieternummer']);
        } catch(e) {
            console.error(e);
        }

		Object.keys(formValues).forEach((key) => {

        try{
        const keyWithoutDots = key.replace(/\./g, '');
            form.getTextField(keyWithoutDots).setText(formValues[key]);
        } catch(e) {
            console.error(e);
        }
		})


		// Step 4: Save the modified PDF.
		const pdfBytes = await pdfDoc.save();

		// Step 5: Create a `Blob` from the PDF bytes,
		const blob = new Blob([pdfBytes], { type: 'application/pdf' });

		// Step 6: Create a download URL for the `Blob`.
		const url = URL.createObjectURL(blob);

		// Step 7: Create a link element and simulate a click event to trigger the download.
		const link = document.createElement('a');
		link.href = url;
		link.download = 'Anerkennungsantrag.pdf';
		link.click();
	};

  const renderFormFields = (isDisabled, fromInnerRender, formExterior, defaultValues, fileList,fileListAnlagen,handlePreview, handleChange, handleChangeAnlagen, handleCancel, formsUmbrella, formIndex, setAnbieterNummerIsFilled, setSignedDocHochgeladen, formIndexOutter) => {
    return (formsUmbrella || []).map((forms, innerIndex) => {
        return (forms.forms || []).map((form, index) => {
          switch (form.type) {

               case 'header':
               return (<>
                    <Card
                      key={index}
                      bordered={false}
                      style={{
                        display: (formIndex === 10) ? 'block' : 'none',
                        marginBottom: '16px',
                        textAlign: 'left',
                        background: '#f0f2f5',
                      }}
                    >
                      <p style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                        {form.label}
                      </p>
                    </Card>

                    { renderFormFields(isDisabled, true , formExterior, defaultValues, fileList,fileListAnlagen,handlePreview, handleChange,  handleChangeAnlagen, handleCancel, [{forms : form.content }], 999, setAnbieterNummerIsFilled, setSignedDocHochgeladen, formIndex) }

                    </>
                  );
            case 'string':
              return (<>
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10)) ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  onChange={(e) => {
                  if (form.label === 'Anbieternummer') {
                    setAnbieterNummerIsFilled(e.target.value != '')
                    console.log('Anbieternummer:', e.target.value)
                  }

                  }
                  }
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <TextArea />
                </Form.Item></>
              );
            case 'number':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input type="number" />
                </Form.Item>
              );
            case 'select':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte auswählen ${form.label}!` }]}
                >
                  <Select disabled={form.label === "Auditart" || form.label === "Audittyp"} placeholder={ form.label === "Auditart" || form.label === "Audittyp" ? "" : "Bitte auswählen"}>
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'multiple select':
              return (
                <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte auswählen ${form.label}!` }]}
                >
                  <Select mode="multiple" placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'checkbox':
              return (
                <Form.Item
                rules={ form.required === true ? [
                           {
                               required: true,
                                                 transform: value => (value || undefined),  // Those two lines
                                                 type: 'boolean',                           // Do the magic
                               message: 'Ihr Einverständnis ist erforderlich',
                           },
                       ] : undefined }
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none'}}
                   key={index} name={form.key} valuePropName="checked">
                  <Checkbox>{form.label}  </Checkbox>
                </Form.Item>
              );
            case 'multiupload':
            return (
                <div  style={{ display: innerIndex === formIndex ? 'block' : 'none'}} >
                { isDisabled ? <MedienCarousel overwriteTitle={"Anlagen"} pickedData={(fileListAnlagen || [])} /> : <Form.Item
                  disabled={false}
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index} name={form.key} label={form.label}>

                  <Upload name="files" disabled={false}
                          crossOrigin={"use-credentials"}
                          data={{
                            ref: "api::audit-bogen",
                            field: "anlagen",
                          }}
                            fileList={(fileListAnlagen || []).map((file) => {
                                  if (!file.thumbUrl) {
                                      if ((((file || {}).response || {})[0] || {}).url) {
                                       file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                                      } else {
                                        file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                                      }
                                  }
                            return file;
                      })}
                      onPreview={handlePreview}
                      onChange={handleChangeAnlagen}

                          action={`${API}/upload`}
                          headers={{ "Authorization": `Bearer ${getToken()}`}}>
                    <Button icon={<UploadOutlined />}>Hochladen</Button>
                  </Upload>
                </Form.Item>}
                </div>
              );
            case 'upload':
              return (
              <div  style={{ display: innerIndex === formIndex ? 'block' : 'none'}} >
                { isDisabled ? <MedienCarousel overwriteTitle={"Unterschriebener Antrag"} pickedData={(fileList || [])} /> : <Form.Item
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none'}}
                  key={index} name={form.key} label={form.label}>

                  <Upload name="files"
                          crossOrigin={"use-credentials"}
                          data={{
                            ref: "api::audit-bogen.audit-bogen",
                            field: "signedpdf",
                          }}
                            fileList={(fileList || []).map((file) => {
                                  console.debug("!!file", file);
                                  if (!file.thumbUrl) {
                                      if ((((file || {}).response || {})[0] || {}).url) {
                                       file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                                      } else {
                                        file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                                      }
                                  }

                            return file;
                      })}
                      onPreview={handlePreview}
                      onChange={handleChange}

                          action={`${API}/upload`}
                          headers={{ "Authorization": `Bearer ${getToken()}`}}>
                    <Button icon={<UploadOutlined />}>Hochladen</Button>
                  </Upload>
                </Form.Item> }
                </div>
              );
              case 'button':
              return (
                    <Button
                  style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none'}}
                   onClick={() => fillForm(formExterior, defaultValues)} icon={<UploadOutlined />}>Antrag als PDF zum drucken und unterschreiben herunterladen</Button>
                    )
            case 'htmlbox':
              return (
                <Card
                   key={index} bordered={false} style={{ display: ((!fromInnerRender && innerIndex === formIndex) || (fromInnerRender && formIndexOutter === 10))  ? 'block' : 'none', marginBottom: '16px', textAlign: 'left', background: '#f0f2f5' }}>
                      <div dangerouslySetInnerHTML={{ __html: form.htmlbox }} style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                      </div>
                    </Card>
              );
            default:
              return null;
          }
        })});
  };


const FormComponent = ( {history, type, id, herstellerData, anbieterData, veranstaltungenData} ) => {

  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();
  const [refId, setRefId] = useState('');
  const [current, setCurrent] = useState(0);
  const [anbieterNummerIsFilled, setAnbieterNummerIsFilled] = useState(false);
  const [signedDocHochgeladen, setSignedDocHochgeladen] = useState(false);
  const [form] = Form.useForm();
 const [defaultValues, setDefaultValues] = useState({});

const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};



    const location = useLocation();

    const atyp = (location.state || {}).auditTyp || (veranstaltung.profilinformation || {}).Audittyp || "Rezertifizierungsaudit";
    const formConfig = atyp ? (atyp === 'Aktenaudit' ? Aktenaudit : atyp === 'Prüfungsaudit' ? Pruefungsaudit : atyp === 'Rezertifizierungsaudit' ? Rezertifizierungsausdit : atyp === 'Seminaraudit' ? Seminaraudit : []) : [];
    useEffect(() => {

    if (
        location.state
    ) {
        if (location.state && location.state.kursnummer) {


        let veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.Kursnummer == ((location || {}).state || {}).kursnummer || veranstaltung.id == id);
                if (!veranstaltung) veranstaltung = {};

        const selectedVeranstaltung = veranstaltungenData.find((v) => v.Kursnummer  == ((location || {}).state || {}).kursnummer || v.Kursnummer == veranstaltung.Kursnummer );
        const anbieter = anbieterData.find((anbieter) => anbieter.createdby == (selectedVeranstaltung || {}).createdby);



        // check if we are on admin/aktionen/Auditbericht
        if ((user || {}).Rolle === 'Anbieter' && history.location.pathname === `/admin/aktionen/Auditbericht`) {
            const userveranstaltung = herstellerData.find((veranstaltung) => veranstaltung.createdby == user.id) || {};
            if ((userveranstaltung || {}).id) {
                history.push(`/admin/aktionen/Auditbericht/${userveranstaltung.id}`);
            }
        }

        if (selectedVeranstaltung && anbieter) {
                defaultValues['Auditor/en'] = (user || {}).Name || "";
                defaultValues['nameAnbieter'] =(( (anbieter || {}).profilinformation || {}).Institut || "");
                defaultValues['Anbieternummer'] =((anbieter || {}).anbieternummer ? ((anbieter || {}).anbieternummer) : "");
                defaultValues['Letze Anerkennung Jahr'] =((anbieter || {}).updatedAt || "-").split('-')[0];
                defaultValues['Adresse Anbieter'] =(( (anbieter || {}).profilinformation || {}).Postanschrift || "");
                defaultValues['Telefon Anbieter'] =(( (anbieter || {}).profilinformation || {}).Telefon || "");
                defaultValues['Veranstaltungsort /Link Zugangscode'] =(( (selectedVeranstaltung || {}).kursinformation || {}).Veranstaltungsort || ((selectedVeranstaltung || {}).kursinformation || {})["Link zum Web-Seminar"] ? (((selectedVeranstaltung || {}).kursinformation || {})["Link zum Web-Seminar"] + " | " + ((selectedVeranstaltung || {}).kursinformation || {})["Zugangspasswort für die Zertifizierungsstelle"]) : "-");
                defaultValues['Ansprechpartner'] =(( (anbieter || {}).profilinformation || {}).Ansprechpartner || "");

                const approvedAudits =  herstellerData.filter((veranstaltung) => veranstaltung.status === "Eingereicht - angenommen" )
                .filter((v) =>  {

                // this approved Audit concerns this anbieter:
                const auditedKurs = veranstaltungenData.find((v) => v.Kursnummer == v.Kursnummer && selectedVeranstaltung.id === v.id);
                const auditedAnbieter = anbieterData.find((a) => a.createdby == auditedKurs.createdby);

                    return auditedAnbieter ? true : false;
                });
                defaultValues['Bisher durchgeführt Anzahl der Audits beim Anbieter'] = approvedAudits.length;

                let audtrue = false
               const latestApprovedAudit = (approvedAudits.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) || [])[0];
               if (latestApprovedAudit) {
                   // check if it is in the same half year as today
                    const today = new Date();
                    const todayMonth = today.getMonth();
                    const todayYear = today.getFullYear();
                    const latestAuditDate = new Date(latestApprovedAudit.updatedAt);
                    const latestAuditMonth = latestAuditDate.getMonth();
                    const latestAuditYear = latestAuditDate.getFullYear();
                    audtrue = (todayYear === latestAuditYear && ((todayMonth > 6 && latestAuditMonth > 6) || (todayMonth <= 6 && latestAuditMonth <= 6)))

               }
                defaultValues['Im laufenden Anerkennungszyklus fand bereits ein kostenpflichtiges Audit statt'] = audtrue ? 'Ja' : 'Nein';

                defaultValues['Auditiertes Seminar'] = ((selectedVeranstaltung || {}).kursinformation || {}).Seminartyp || "";
        }

        if (Object.keys(veranstaltung.profilinformation || {}).length > 0) {
            Object.keys(veranstaltung.profilinformation).forEach((key) => {
                defaultValues[key] = veranstaltung.profilinformation[key];
            });
        }

        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

         setDefaultValues({
                 ...defaultValues,
                Kursnummer: (location.state || {}).kursnummer,
                Auditart: (location.state || {}).auditArt,
                Audittyp: (location.state || {}).auditTyp,
            });
        }
    }
    // Update the ref with the current location state
  }, [veranstaltungenData, location.pathname]);

const [fileListAnlagen, setFileListAnlagen] = useState(((veranstaltung || {}).anlagen || {}).data || []);
const [fileList, setFileList] = useState(((veranstaltung || {}).signedpdf || {}).data || []);
const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {

      if (!file.thumbUrl && !file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.thumbUrl || file.url || file.preview || ((((file || {}).attributes || {}).formats || {}).thumbnail || {}).url);
      setPreviewOpen(true);
      setPreviewTitle((((file || {}).attributes) || {}).name || file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
const handleChangeAnlagen = (props) => {
    setFileListAnlagen(props.fileList);
}


useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");
        if (true || isFormEmpty) {
            form.setFieldsValue(defaultValues)
            if (defaultValues.signedpdf) {
                setFileList(defaultValues.signedpdf);
            }
            if (defaultValues.anlagen) {
                setFileListAnlagen(defaultValues.anlagen);
            }
        }
    }, [form, defaultValues])


// on mount check if this user already has a veranstaltung

    useEffect(() => {
        const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id);
                if (!veranstaltung) return;

        const selectedVeranstaltung = veranstaltungenData.find((v) => v.Kursnummer == veranstaltung.Kursnummer || veranstaltung.Kursnummer == ((location || {}).state || {}).kursnummer);
        const anbieter = anbieterData.find((anbieter) => anbieter.createdby == (selectedVeranstaltung || {}).createdby);



        // check if we are on admin/aktionen/Auditbericht
        if ((user || {}).Rolle === 'Anbieter' && history.location.pathname === `/admin/aktionen/Auditbericht`) {
            const userveranstaltung = herstellerData.find((veranstaltung) => veranstaltung.createdby == user.id) || {};
            if ((userveranstaltung || {}).id) {
                history.push(`/admin/aktionen/Auditbericht/${userveranstaltung.id}`);
            }
        }

        if (selectedVeranstaltung && anbieter) {
                defaultValues['Auditor/en'] = (user || {}).Name || "";
                defaultValues['nameAnbieter'] =(( (anbieter || {}).profilinformation || {}).Institut || "");
                defaultValues['Anbieternummer'] =((anbieter || {}).anbieternummer ? ((anbieter || {}).anbieternummer) : "");
                defaultValues['Letze Anerkennung Jahr'] =((anbieter || {}).updatedAt || "-").split('-')[0];
                defaultValues['Adresse Anbieter'] =(( (anbieter || {}).profilinformation || {}).Postanschrift || "");
                defaultValues['Telefon Anbieter'] =(( (anbieter || {}).profilinformation || {}).Telefon || "");
                defaultValues['Veranstaltungsort /Link Zugangscode'] =(( (selectedVeranstaltung || {}).kursinformation || {}).Veranstaltungsort ||  ((selectedVeranstaltung || {}).kursinformation || {})["Link zum Web-Seminar"] ? (((selectedVeranstaltung || {}).kursinformation || {})["Link zum Web-Seminar"] + " | " + ((selectedVeranstaltung || {}).kursinformation || {})["Zugangspasswort für die Zertifizierungsstelle"]) : "-");
                defaultValues['Ansprechpartner'] =(( (anbieter || {}).profilinformation || {}).Ansprechpartner || "");

                const approvedAudits =  herstellerData.filter((veranstaltung) => veranstaltung.status === "Eingereicht - angenommen" )
                .filter((v) =>  {

                // this approved Audit concerns this anbieter:
                const auditedKurs = veranstaltungenData.find((v) => v.Kursnummer == v.Kursnummer && selectedVeranstaltung.id === v.id);
                const auditedAnbieter = anbieterData.find((a) => a.createdby == auditedKurs.createdby);

                    return auditedAnbieter ? true : false;
                });
                defaultValues['Bisher durchgeführt Anzahl der Audits beim Anbieter'] = approvedAudits.length;

                let audtrue = false
               const latestApprovedAudit = (approvedAudits.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) || [])[0];
               if (latestApprovedAudit) {
                   // check if it is in the same half year as today
                    const today = new Date();
                    const todayMonth = today.getMonth();
                    const todayYear = today.getFullYear();
                    const latestAuditDate = new Date(latestApprovedAudit.updatedAt);
                    const latestAuditMonth = latestAuditDate.getMonth();
                    const latestAuditYear = latestAuditDate.getFullYear();
                    audtrue = (todayYear === latestAuditYear && ((todayMonth > 6 && latestAuditMonth > 6) || (todayMonth <= 6 && latestAuditMonth <= 6)))

               }
                defaultValues['Im laufenden Anerkennungszyklus fand bereits ein kostenpflichtiges Audit statt'] = audtrue ? 'Ja' : 'Nein';

                defaultValues['Auditiertes Seminar'] = ((selectedVeranstaltung || {}).kursinformation || {}).Seminartyp || "";
        }

        if (Object.keys(veranstaltung.profilinformation || {}).length > 0) {
            Object.keys(veranstaltung.profilinformation).forEach((key) => {
                defaultValues[key] = veranstaltung.profilinformation[key];
            });
        }

        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [herstellerData]);


  const saveFortschritt = async (status) => {
    setLoading(true);
    try {
        const data = form.getFieldsValue();
        const referent = herstellerData.find((referent) => referent.id == id);
        let response;
        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

         if (!dataToPost["Bild"] || (referent && (dataToPost["Bild"] || {}).data)) delete dataToPost["Bild"];

                 dataToPost.signedpdf = (fileList || []).map( media => {
                             if (((media.response) || []) [0]) {
                                 return media.response[0];
                             } else {
                                 return media;
                             }
                          });

                                           dataToPost.anlagen = (fileListAnlagen || []).map( media => {
                                                       if (((media.response) || []) [0]) {
                                                           return media.response[0];
                                                       } else {
                                                           return media;
                                                       }
                                                    });

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Bild"]  = dataToPost.dragger[0].response[0];


         dataToPost.createdby = user.id;

         if (status) {
            dataToPost.status = status;
         }

         delete dataToPost.dragger;
        //         delete dataToPost.Vergabestelle;
        //          if (Object.keys(dataToPost.Vergabestelle).length === 0 || typeof ((dataToPost || {}).Vergabestelle || {}).Punkte == "undefined") delete dataToPost.Vergabestelle;

        dataToPost.profilinformation = data;
let responseData = {};
      if (referent) {
        response = await fetch(`${API}/audit-bogens/${referent.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
        responseData = await response.json();
        message.success("Daten erfolgreich gespeichert!");
        if (status) { history.push('/admin/aktionen'); }
      } else {
        response = await fetch(`${API}/audit-bogens`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
        responseData = await response.json();
        message.success("Daten erfolgreich gespeichert!");

          if (status) {
            history.push('/admin/aktionen');
          } else {
            history.push(`/admin/aktionen/Auditbericht/${responseData.data.id}`);
          }

      }
      // Update logic based on your application's requirements
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  const onNext = () => {
   // form.validateFields().then(() => {
      setCurrent(current + 1);
   // }).catch(errorInfo => {
      //message.error('Bitte füllen Sie alle Pflichtfelder aus.');
   // });
  };

  const onPrev = () => {
    setCurrent(current - 1);
  };

   const onChange = (value: number) => {
      console.log('onChange:', value);
      setCurrent(value);
    };

  const onFinish = (values) => {
      form.validateFields().then(() => {
            console.log('Form values:', values);
            message.success('Formular erfolgreich übermittelt!');
      }).catch(errorInfo => {
        message.error('Bitte füllen Sie alle Pflichtfelder aus.');
      });
  };

  const isDisabled = (user || {}).Rolle !== 'Auditor' ;

  let availabelKurse = ((user || {}).AuditingKursnummer || "").split(",") || [];

//  const hasBewertung =
const currData = herstellerData.find((referent) => referent.id == id);
const bewertung = currData ? currData.bewertung : false;
console.debug("tata",  herstellerData.find((referent) => referent.id == id));
  return (<>


     { bewertung && <Row style={{ rowGap: 18, background: '#eb008c24', marginBottom: 24 }} gutter={[18, 18]}>
            <Col md={18} style={{ paddingLeft: '4rem', textAlign: 'left'}}>
            <div>
            <h2>Auditbewertung</h2>
            <p><span style={{ fontWeight: 800, fontSize: '18px' }}>{bewertung}</span></p>
            { currData.kommentar && <p>Kommentar: <span style={{ fontWeight: 800, fontSize: '20px' }}>{currData.kommentar}</span></p> }
            </div>
            <Divider />
            </Col>
        </Row>}
     <Row gutter={[18, 18]}>
     <Col md={3}>

<Steps direction="vertical" onChange={onChange} current={current} style={{ marginBottom: '20px' }}>
  {formConfig.map((item, index) => (
    <Step
      key={index}
      title={
        <Tooltip arrow={false} placement="right" title={item.title || ""}>
          <span>{(item.title || "").substr(0, (item.title || "").indexOf(" "))}</span>
        </Tooltip>
      }
      icon={<Tooltip arrow={false} placement="right" title={item.title || ""}>
                      {item.icon}
                    </Tooltip>}
    />
  ))}
</Steps>
        </Col>
        <Col md={21}>
            <Form
            disabled={isDisabled}
            initialValues={defaultValues} form={form} layout="vertical" onFinish={onFinish}>
              <Card
                    title={formConfig[current].title}
                  actions={[
                      (user || {}).Rolle === 'Auditor' && <Button onClick={() => saveFortschritt()} style={{ margin: '0 8px' }}
                          type="primary"
                          icon={<SaveOutlined />}>
                        Fortschritt speichern
                      </Button>,
                      <Button disabled={!(current > 0)} style={{ margin: '0 8px' }} onClick={onPrev}>
                        Zurück
                      </Button>,
                      <Button disabled={!(current < formConfig.length - 1)} type="primary" onClick={onNext}>
                        Weiter
                      </Button>,
                       (user || {}).Rolle === 'ICW-Admin' && <Button icon={<CheckCircleOutlined />} danger disabled={false} type="primary" onClick={() => {
                            history.push('/admin/aktionen/Auditorverwaltung', {openAblehnen: id});
                      }}>
                        Bericht ablehnen
                      </Button>,
                       (user || {}).Rolle === 'ICW-Admin' && <Button style={{ color: 'white', background: '#52c41a' }} icon={<CheckCircleOutlined />} disabled={false} type="primary" onClick={() => {
                            history.push('/admin/aktionen/Auditorverwaltung', {openApproveAkzeptieren: id});
                      }}>
                        Bericht akzeptieren
                      </Button>,

                      (user || {}).Rolle === 'Auditor' && <Button onClick={() => {
                                form.validateFields().then(() => {
                                      saveFortschritt("Eingereicht - in Bearbeitung")
                                }).catch(errorInfo => {
                                message.open({
                                      type: 'error',
                                      content: 'Antrag noch nicht komplett. Bitte füllen Sie alle Pflichtfelder aus.',
                                      className: 'custom-class',
                                      style: {
                                        padding: '3rem',
                                        marginTop: '20vh',
                                        fontSize: '20px',
                                        backgroundColor: '#eb008c',
                                        fontWeight: 800
                                      },
                                    });
                                });



                      }} disabled={
                      /* is Anbieternummer filled? */
                        !(form.getFieldValue('Kursnummer')) } type="primary" htmlType="submit">
                        Einreichen
                      </Button>
                    ]}
                    style={{ margin: '0 auto' }}>
                    <div style={{ height: 414, overflow: 'auto' }}>
                    { formConfig[current].htmlbox && <Card bordered={false} style={{ paddingBottom: '11px', marginBottom: '16px', textAlign: 'left', background: '#f0f2f5' }}>
                          <Typography.Title level={4} style={{ color: '#1890ff' }}>{form.label}</Typography.Title>
                          <div dangerouslySetInnerHTML={{ __html: formConfig[current].htmlbox }} ></div>
                        </Card> }


                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'nAnbieter'}
                  name={'nameAnbieter'}
                  label={'Name Anbieter'} disabled={true}
                    >
                  <Input  disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Anbieternummer'}
                  name={'Anbieternummer'}
                  label={'Anbieter-Nr'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Letze Anerkennung Jahr'}
                  name={'Letze Anerkennung Jahr'}
                  label={'Letze Anerkennung Jahr'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Adresse Anbieter'}
                  name={'Adresse Anbieter'}
                  label={'Adresse Anbieter'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Telefon Anbieter'}
                  name={'Telefon Anbieter'}
                  label={'Telefon Anbieter'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Veranstaltungsort /Link Zugangscode'}
                  name={'Veranstaltungsort /Link Zugangscode'}
                  label={'Veranstaltungsort /Link Zugangscode'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Ansprechpartner'}
                  name={'Ansprechpartner'}
                  label={'Ansprechpartner'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Bisher durchgeführt Anzahl der Audits beim Anbieter'}
                  name={'Bisher durchgeführt Anzahl der Audits beim Anbieter'}
                  label={'Bisher durchgeführt Anzahl der Audits beim Anbieter'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Im laufenden Anerkennungszyklus fand bereits ein kostenpflichtiges Audit statt'}
                  name={'Im laufenden Anerkennungszyklus fand bereits ein kostenpflichtiges Audit statt'}
                  label={'Im laufenden Anerkennungszyklus fand bereits ein kostenpflichtiges Audit statt'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Auditor/en'}
                  name={'Auditor/en'}
                  label={'Auditor/en'} disabled={true}
                    >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Auditiertes Seminar'}
                  name={'Auditiertes Seminar'}
                  label={'Auditiertes Seminar'} disabled={true}
                    >
                  <Input  disabled={true} />
                </Form.Item>

                <Form.Item
                  style={{ display: current === 0 ? 'block' : 'none'}}
                  key={'Kursnummer'}
                  name={'Kursnummer'}
                  label={'Kursnummer'} disabled={true}
                  rules={[{ required: true, message: `Bitte Kursnummer auswählen` }]}
                    >
                  <Select  disabled={true} placeholder="">
                    {availabelKurse.map(ak => (
                      <Option key={ak} value={ak}>
                        {ak}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <p style={{ display: current === 0 ? 'block' : 'none'}}><hr /><br/></p>

                      { renderFormFields(isDisabled, false, form, defaultValues, fileList,fileListAnlagen, handlePreview, handleChange,handleChangeAnlagen, handleCancel, formConfig, current, setAnbieterNummerIsFilled, setSignedDocHochgeladen) }
                      <Divider />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      </div>
                    </div>
      </Card>
    </Form>
            </Col>
        </Row></>
  );
};

export default FormComponent;
