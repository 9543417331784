import React from "react";
import {
  UserOutlined,
  FormOutlined,
  TrophyOutlined,
} from '@ant-design/icons';

export const formConfig = [
  {
    title: '1. Dozentenprofil',
    icon: <FormOutlined />,
    forms: [
      { htmlbox: 'Eingegebene Dozenten können in der Seminarbeantragung verwendet werden.', type: 'htmlbox' },
      { label: 'Anrede', type: 'select', key: 'Anrede', options: ['', 'Frau', 'Herr', 'Divers'] },
      { label: 'Titel', type: 'select', key: 'Titel', options: ['', 'Dr.', 'Prof.'] },
      { label: 'Name', type: 'string', key: 'Name' },
      { label: 'Vorname', type: 'string', key: 'Vorname' },
      { label: 'Basisqualifikation', type: 'multiple select', key: 'Basisqualifikation', options: ['Apotheker', 'Ärzte (Humanmedizin)', 'Heilpraktiker', 'Heilerziehungspfleger', 'Medizinische Fachangestellte (MFA)', 'Operationstechnische Assistenten (OTA)', 'Gesundheits- und Krankenpfleger', 'Gesundheits- und Kinderkrankenpfleger', 'Altenpfleger','Pflegefrau/-mann (Pflegefachperson)', 'Podologen', 'Physiotherapeuten mit Zusatzqualifikation Lymphtherapeut'] },
      { label: 'Zusatzqualifikation', type: 'multiple select', key: 'Zusatzqualifikation', options: ['Wundexperte ICW', 'Fachtherapeut Wunde ICW', 'Pflegetherapeut Wunde ICW'] },
      { label: 'Andere Qualifikation', type: 'string', key: 'AndereQualifikation' },
      { label: 'Berufspraxis im Themengebiet', type: 'string', key: 'Berufspraxis_im_Themengebiet' },
      { label: 'Derzeitiger Arbeitgeber', type: 'string', key: 'Derzeitiger_Arbeitgeber' },
      { label: 'Derzeitige Tätigkeit', type: 'string', key: 'Derzeitige_Tätigkeit' },
      { label: 'Einsatz im Seminarthema', type: 'multiple select', key: 'Einsatz_im_Seminarthema', options: [ 'Dekubitus/Prophylaxe', 'Diabetisches Fußsyndrom/Prophylaxe', 'Edukation', 'Expertenstandard', 'Haut/Hautpflege', 'Hygiene', 'Recht', 'Palliativ/Tumorwunden', 'Ulcus Cruris', 'Schmerz', 'Wundversorgung/Wundverbände', 'Wundarten und -heilung', 'Wundbeurteilung/Dokumentation', 'Kompression' ] },
      { label: 'Anderer Einsatz im Seminarthema', type: 'string', key: 'AndererEinsatzSeminarthema' },
      { label: 'Einsatz bei Seminar', type: 'multiple select', key: 'Einsatz_bei_Seminar', options: ['Wundexperte ICW', 'Fachtherapeut Wunde ICW', 'Pflegetherapeut Wunde ICW', 'Ärztlicher Wundexperte ICW', 'Rezertifizierungskurse'] },
      { label: 'Anderer Einsatz bei Seminar', type: 'string', key: 'AndererEinsatzSeminar' },
    ],
  },
  {
    title: '2. Abschließen',
    icon: <TrophyOutlined />,
    forms: [
      { htmlbox: 'Um den Eintrag des Dozenten fertigzustellen, laden Sie die Qualifikationsnachweise (Berufsurkunde, Zertifikate WE/FT/PT, Leitungs- Dozentenseminar) bitte als PDF hoch.', type: 'htmlbox' },
      { label: 'Qualifikationsnachweise hochladen:', type: 'upload', key: 'Qualifikationsnachweise' },
    ],
  },
];
