import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Button, Select, Checkbox, Tooltip, DatePicker, Steps, Divider, Typography, message, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';

import MedienCarousel from "../Components/MedienCarousel";
import { API, API_BASE } from "../constant";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";
import { formConfig } from "../configs/DozentAnerkennung";
const { Step } = Steps;
const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const fillForm = async (formInput) => {
		// Step 1: Load the PDF form.
        const formUrl = '/pdfs/Anerkennung.pdf';
		const formPdfBytes = await fetch(formUrl).then((res) => {
		    console.debug(res, "!!pdf res!!");
			return res.arrayBuffer()
		});
		const pdfDoc = await PDFDocument.load(formPdfBytes);

		// Step 2: Retrieve the form fields.
		const form = pdfDoc.getForm();

		console.debug(formInput, "!!pdf form!!", form.getFields());
		 form.getTextField('Name').setText('Mario');

		// Step 4: Save the modified PDF.
		const pdfBytes = await pdfDoc.save();

		// Step 5: Create a `Blob` from the PDF bytes,
		const blob = new Blob([pdfBytes], { type: 'application/pdf' });

		// Step 6: Create a download URL for the `Blob`.
		const url = URL.createObjectURL(blob);

		// Step 7: Create a link element and simulate a click event to trigger the download.
		const link = document.createElement('a');
		link.href = url;
		link.download = 'Anerkennungsantrag.pdf';
		link.click();
	};

  const renderFormFields = (user, formExterior, defaultValues, fileList,handlePreview, handleChange, handleCancel, formsUmbrella, formIndex, setAnbieterNummerIsFilled, setSignedDocHochgeladen) => {
    console.debug(formExterior,"defsaultValues, fileList",defaultValues, fileList);
    return (formsUmbrella || []).map((forms, innerIndex) => {
        console.debug("!!forms", forms.forms);
        return (forms.forms || []).map((form, index) => {
          switch (form.type) {
            case 'string':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  onChange={(e) => {
                  if (form.label === 'Anbieternummer') {
                    setAnbieterNummerIsFilled(e.target.value != '')
                    console.log('Anbieternummer:', e.target.value)
                  }

                  }
                  }
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input />
                </Form.Item>
              );
            case 'number':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input type="number" />
                </Form.Item>
              );
            case 'select':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte auswählen ${form.label}!` }]}
                >
                  <Select placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'multiple select':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte auswählen ${form.label}!` }]}
                >
                  <Select mode="multiple" placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'checkbox':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                   key={index} name={form.key} valuePropName="checked">
                  <Checkbox>{form.label}</Checkbox>
                </Form.Item>
              );
            case 'upload':
              console.debug("!!fileList", fileList);
  const isDisabled = (user || {}).Rolle === 'ICW-Admin' || (user || {}).Rolle === 'Auditor' ;
              return (
                isDisabled ? <div   style={{ display: innerIndex === formIndex ? 'block' : 'none'}}><MedienCarousel overwriteTitle={"Anlagen"} pickedData={(fileList || [])} /></div> : <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index} name={form.key} label={form.label}>

                  <Upload name="files"
                          crossOrigin={"use-credentials"}
                          data={{
                            ref: "api::dozent",
                            field: "anlagen",
                          }}
                            fileList={(fileList || []).map((file) => {
                                  console.debug("!!file", file);
                                  if (!file.thumbUrl) {
                                      if ((((file || {}).response || {})[0] || {}).url) {
                                       file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                                      } else {
                                        file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                                      }
                                  }

                            return file;
                      })}
                      onPreview={handlePreview}
                      onChange={handleChange}

                          action={`${API}/upload`}
                          headers={{ "Authorization": `Bearer ${getToken()}`}}>
                    <Button icon={<UploadOutlined />}>Hochladen</Button>
                  </Upload>
                </Form.Item>
              );
              case 'button':
              return (
                    <Button
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                   onClick={() => fillForm(formExterior)} icon={<UploadOutlined />}>Antrag als PDF zum drucken und unterschreiben herunterladen</Button>
                    )
            case 'htmlbox':
              return (
                <Card
                   key={index} bordered={false} style={{ display: innerIndex === formIndex ? 'block' : 'none', marginBottom: '16px', textAlign: 'left', background: '#f0f2f5' }}>
                      <p style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                        {form.htmlbox}
                      </p>
                    </Card>
              );
            default:
              return null;
          }
        })});
  };


const FormComponent = ( {history, type, id, herstellerData} ) => {

  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();
  const [refId, setRefId] = useState('');
  const [current, setCurrent] = useState(0);
  const [anbieterNummerIsFilled, setAnbieterNummerIsFilled] = useState(false);
  const [signedDocHochgeladen, setSignedDocHochgeladen] = useState(false);
  const [form] = Form.useForm();
 const [defaultValues, setDefaultValues] = useState({});

const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};
const [fileList, setFileList] = useState(((veranstaltung || {}).anlagen || {}).data || []);
const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {

    console.debug("file",file);
      if (!file.thumbUrl && !file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.thumbUrl || file.url || file.preview || ((((file || {}).attributes || {}).formats || {}).thumbnail || {}).url);
      setPreviewOpen(true);
      setPreviewTitle((((file || {}).attributes) || {}).name || file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
            if (defaultValues.anlagen) {
                setFileList(defaultValues.anlagen);
            }
        }
    }, [form, defaultValues])

    useEffect(() => {
        const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};

        const defaultValues = {};

        if (Object.keys(veranstaltung.dozentinformation || {}).length > 0) {
            Object.keys(veranstaltung.dozentinformation).forEach((key) => {
                defaultValues[key] = veranstaltung.dozentinformation[key];
            });
        }

        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [herstellerData]);


  const saveFortschritt = async (status) => {
    setLoading(true);
    try {
        const data = form.getFieldsValue();
        const referent = herstellerData.find((referent) => referent.id == id);
        let response;
        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

         if (!dataToPost["Bild"] || (referent && (dataToPost["Bild"] || {}).data)) delete dataToPost["Bild"];
                 dataToPost.anlagen = (fileList || []).map( media => {
                             if (((media.response) || []) [0]) {
                                 return media.response[0];
                             } else {
                                 return media;
                             }
                          });

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Bild"]  = dataToPost.dragger[0].response[0];


         dataToPost.createdby = user.id;

         if (status) {
            dataToPost.status = status;
         }

         delete dataToPost.dragger;
        //         delete dataToPost.Vergabestelle;
        //          if (Object.keys(dataToPost.Vergabestelle).length === 0 || typeof ((dataToPost || {}).Vergabestelle || {}).Punkte == "undefined") delete dataToPost.Vergabestelle;

        dataToPost.dozentinformation = data;

      let responseData = {};
      if (referent) {
        response = await fetch(`${API}/dozents/${referent.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
        responseData = await response.json();
        if (status) {
                    history.push('/admin/aktionen/Dozenten');
                  }
      } else {
        response = await fetch(`${API}/dozents`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
        responseData = await response.json();
        message.success("Daten erfolgreich gespeichert!");

          if (status) {
            history.push('/admin/aktionen/Dozenten');
          } else {
            history.push(`/admin/aktionen/Dozent/${responseData.data.id}`);
          }
      }

      // Update logic based on your application's requirements

    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  const onNext = () => {
   // form.validateFields().then(() => {
      setCurrent(current + 1);
   // }).catch(errorInfo => {
      //message.error('Bitte füllen Sie alle Pflichtfelder aus.');
   // });
  };

  const onPrev = () => {
    setCurrent(current - 1);
  };

   const onChange = (value: number) => {
      console.log('onChange:', value);
      setCurrent(value);
    };

  const onFinish = (values) => {
    console.log('Form values:', values);
    message.success('Formular erfolgreich übermittelt!');
  };

  return (
     <Row gutter={[18, 18]}>
     <Col md={3}>
        <Steps direction="vertical" onChange={onChange} current={current} style={{ marginBottom: '20px' }}>
          {formConfig.map((item, index) => (
            <Step
      key={index}
      title={
        <Tooltip arrow={false} placement="right" title={item.title || ""}>
          <span>{(item.title || "").substr(0, (item.title || "").indexOf(" "))}</span>
        </Tooltip>
      }
      icon={<Tooltip arrow={false} placement="right" title={item.title || ""}>
                      {item.icon}
                    </Tooltip>}
    />
          ))}
        </Steps>
        </Col>
        <Col md={21}>
            <Form
            disabled={(user || {}).Rolle === 'ICW-Admin' || (user || {}).Rolle === 'Auditor' }
            initialValues={defaultValues} form={form} layout="vertical" onFinish={onFinish}>
              <Card
                    title={formConfig[current].title }
                  actions={[
                      (user || {}).Rolle !== 'ICW-Admin' && (user || {}).Rolle !== 'Auditor' && <Button onClick={() => saveFortschritt()} style={{ margin: '0 8px' }}
                          type="primary"
                          icon={<SaveOutlined />}>
                        Fortschritt speichern
                      </Button>,
                      <Button disabled={!(current > 0)} style={{ margin: '0 8px' }} onClick={onPrev}>
                        Zurück
                      </Button>,
                      <Button disabled={!(current < formConfig.length - 1)} type="primary" onClick={onNext}>
                        Weiter
                      </Button>,

                         (user || {}).Rolle === 'ICW-Admin' && <Button icon={<CheckCircleOutlined />} danger disabled={false} type="primary" onClick={() => {
                              history.push('/admin/aktionen/Dozentenverwaltung', {open: id});
                        }}>
                          Ablehnen
                        </Button>,
                         (user || {}).Rolle === 'ICW-Admin' && <Button style={{ color: 'white', background: '#52c41a' }} icon={<CheckCircleOutlined />} disabled={false} type="primary" onClick={() => {
                              history.push('/admin/aktionen/Dozentenverwaltung', {openApprove: id});
                        }}>
                          Akzeptieren
                        </Button>,
                      (user || {}).Rolle !== 'ICW-Admin' && (user || {}).Rolle !== 'Auditor' && <Button onClick={() => saveFortschritt("Eingereicht - in Bearbeitung")} disabled={
                      /* is Anbieternummer filled? */
                        !(form.getFieldValue('Anbieternummer')) && !anbieterNummerIsFilled && !defaultValues.anbieternummer &&
                      !((fileList || []).length > 0)} type="primary" htmlType="submit">
                        Einreichen
                      </Button>
                    ]}
                    style={{ margin: '0 auto' }}>
                    <div style={{ height: 414, overflow: 'auto' }}>
                    { formConfig[current].htmlbox && <Card bordered={false} style={{ paddingBottom: '11px', marginBottom: '16px', textAlign: 'right', background: '#f0f2f5' }}>
                          <Typography.Title level={4} style={{ color: '#1890ff' }}>{form.label}</Typography.Title>
                          <Typography.Paragraph style={{ marginLeft: '16px', marginRight: '16px' }}>
                            {formConfig[current].htmlbox}
                          </Typography.Paragraph>
                        </Card> }

                      { renderFormFields(user, form, defaultValues, fileList, handlePreview, handleChange, handleCancel, formConfig, current, setAnbieterNummerIsFilled, setSignedDocHochgeladen) }
                      <Divider />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      </div>
                    </div>
      </Card>
    </Form>
            </Col>
        </Row>
  );
};

export default FormComponent;
